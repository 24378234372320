<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 265px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">{{ this.adminpagetype.title_jp }}<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{ name: 'Dashboard' }"
                >ダッシュボード</router-link
              >
            </li>
            <li>
              <span>{{ this.adminpagetype.title_jp }}編集</span>
            </li>
          </ul>
        </nav>
      </div>
      <div class="action_wrap m_b5" v-if="commonValidationError">
        <p class="s_err">
          入力内容に不備があります。<span
            ><i class="fas fa-times msg_remove"></i
          ></span>
        </p>
      </div>

      <div
        class="modal"
        id="discardModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal_inner confirm">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form_outwrap">
                  <div class="modal_header">
                    <p class="ttl_header">確認</p>
                  </div>
                  <div class="modal_wrap">
                    <div class="for_wrap h_80">
                      <div class="modal_contents">
                        <p>
                          <span
                            >入力途中の内容は削除されますがよろしいですか？</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="modal_footer">
                    <div class="flex_wrap f_c">
                      <div class="btn_norm reverse" id="cancel_bk">
                        <button
                          name=""
                          value="キャンセル"
                          class="close"
                          dusk="delete_data"
                          @click="closeDiscard()"
                        >
                          キャンセル
                        </button>
                      </div>
                      <div class="btn_norm delete">
                        <button
                          @click="discardModal()"
                          class="btn btn-primary cln_form_btn"
                          value="アーカイブ"
                          title="削除"
                          dusk="save"
                        >
                          実行
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            id="close_btn"
            class="pop-remove_btn ttoltip tip-top close"
            @click="closeDiscard()"
          >
            <i class="fas fa-times"></i>
          </p>
          <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
        </div>
        <!--modal_inner-->
      </div>
      <!--modal-->
      <!-- <Spinner v-if="spinner" /> -->
      <!-- Vee-validate form field -->
      <Form
        enctype="multipart/form-data"
        autocomplete="off"
        @submit="update(editmessage.id)"
        class="createform"
      >
        <div class="command_wrap command_bottom">
          <!--leftarea-->
          <div class="rightarea">
            <div>
              <div class="btn_norm">
                <button type="submit" dusk="com_save_button">保存</button>
              </div>
            </div>
            <div class="commandtext_wrap">
              <div class="commandtextlist"></div>
            </div>
            <!--commandtext_wrap-->
          </div>
          <!--rightarea-->
        </div>
        <!--command_wrap-->

        <div class="contentout_wrap">
          <div class="content_wrap twocolumn">
            <div class="box_wrap left">
              <h2 class="ttl_2">新規{{ this.adminpagetype.title_jp }}</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_200px">タイトル</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="title"
                                  type="text"
                                  class="form-control"
                                  placeholder="タイトルを入力してください"
                                  v-model="editmessage.title"
                                  id="title"
                                  :rules="isRequired"
                                />
                                <ErrorMessage class="each_msg" name="title" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_200px">本文</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  v-model="editmessage.content"
                                  :config="editorConfig"
                                  @input="onEditorInput"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  v-model="editmessage.content"
                                  id="content"
                                  :rules="isRequiredContent"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap right">
              <h2 class="ttl_2">公開情報</h2>
              <div class="form_outwrap">
                <div class="wrap wid_100per p_r0">
                  <div><p class="ttl_3">公開ステータス</p></div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        required=""
                        name="status"
                        as="select"
                        class="form-control"
                        v-model="editmessage.status"
                        :rules="isRequired"
                      >
                        <option value="下書き">下書き</option>
                        <option value="公開">公開</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="status" />
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap wid_100per p_r0" style="display: none">
                  <div><p class="ttl_3">カテゴリ</p></div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        name="type"
                        as="select"
                        class="form-control"
                        v-model="editmessage.type"
                      >
                        <option value="news">お知らせ</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="type" />
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap p_r0">
                  <p class="ttl_3">公開日</p>
                  <div class="in_wrap">
                    <Field
                      name="published_at"
                      type="datetime-local"
                      class="form-control"
                      v-model="editmessage.published_at"
                    />
                    <ErrorMessage class="each_msg" name="published_at" />
                    <!--datepickbox-->
                  </div>
                  <!--in_wrap-->
                </div>
                <!--wrap-->
              </div>
              <!--form_outwrap-->
            </div>
            <!--box_wrap-->
          </div>
          <!--content_wrap-->
        </div>
        <!--contentoutwrap-->
      </Form>
    </div>
    <!--maincontentinner-->
    <template id="item_doc_Template">
      <div class="row_wrap new_item sort_item attached_wrap">
        <div class="col">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <input
                  type="hidden"
                  name="jdg_val[]"
                  class="fileHiddenName"
                  value="new"
                />
                <input
                  name="rel_doc_title[]"
                  type="text"
                  class="fileName uploadFileName"
                  required
                  placeholder="例：1月の営業スケジュールについて"
                  value=""
                />
              </div>
              <div class="fileDisplayName"></div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="col item_list">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <div class="filegroup">
                  <input
                    type="file"
                    name="rel_doc[]"
                    class="input-file"
                    style="display: none"
                    accept=".pdf"
                    required
                  />
                  <label class="filelabel fileup-btn">ファイルを選択</label>
                </div>
              </div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="icon col wid_50px t_c">
          <div class="remove_wrap">
            <a
              href=""
              class="item_remove_btn remove_btn tooltip tip-left delete_task_link"
              title="削除"
              ><i class="fas fa-times"></i
            ></a>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/select2/select2.min.js";
import $ from "jquery";
import message from "@/apis/Message";
import user from "@/apis/User";
import AdminPageType from "@/const/AdminPageType.json";
//import ClassicEditor from "ckeditor5-build-classic-with-base64-and-autolink/build/ckeditor";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import CKFinder from "@ckeditor/ckeditor5-ckfinder/src/ckfinder";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import UploadAdapterPlugin from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";

export default {
  name: "message",
  data() {
    return {
      userlists: "",
      taglists: "",
      messages: [],
      editmessage: {
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        // target_type:"users",
        type: this.$route.params.type,
        content_type: "HTML",
        thanks_type: [],
      },
      processing: false,
      AdminPageType() {
        return AdminPageType;
      },
      adminpagetype: {},
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ParagraphPlugin,
          CKFinder,
          Base64UploadAdapter,
          ImagePlugin,
          UploadAdapterPlugin,
          SourceEditing,
          Heading,
          Indent,
          ListStyle,
          ImageInsert,
          BlockQuote,
          Table,
          TableToolbar,
          MediaEmbed,
        ],
        image: {
          toolbar: [
            "imageStyle:block",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
          ],
        },

        toolbar: {
          items: [
            "heading",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "uploadImage",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
            "undo",
            "redo",
            "sourceEditing",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        ckfinder: {
          options: {
            resourceType: "Images",
          },
        },
      },
      editorValidation: true,
      old_fileid: "",
      before_thanks_type: [],
      attached_file: [],
      delete_attached_file: [],
      commonValidationError: false,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    console.log(ClassicEditor);

    this.message_type = this.$route.params.type;
    this.adminpagetype = AdminPageType[this.message_type];
    this.GetPageId();
    message
      .getcompany("ceo")
      .then((response) => {
        if (response != null) {
          console.log(response.data.data[0].id);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
    message
      .getcompany("business")
      .then((response) => {
        if (response != null) {
          console.log(response.data.data[0].id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    message
      .getcompany("policy")
      .then((response) => {
        if (response != null) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    let vueThis = this;
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
  },
  methods: {
    onEditorInput(data, event, editor) {
      editor.editing.view.document.on(
        "enter",
        (evt, data) => {
          if (data.isSoft) {
            editor.execute("enter");
          } else {
            editor.execute("shiftEnter");
          }

          data.preventDefault();
          evt.stop();
          editor.editing.view.scrollToTheSelection();
        },
        { priority: "high" }
      );
    },
    GetPageId() {
      message
        .list(this.message_type)
        .then((response) => {
          if (response != null) {
            console.log(response);
            this.message_id = response.data.data[0].id;
            this.editmessageView(this.message_id);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    isRequired(value) {
      if (value) {
        return true;
      }
      return "必須です";
    },
    isRequiredContent(value) {
      if (value || $(".ck-source-editing-area").data("value")) {
        return true;
      }
      return "必須です";
    },
    isRequiredArr(value) {
      if (value.length != 0) {
        return true;
      }
      return "必須です";
    },
    editmessageView(id) {
      this.loader = true;
      message
        .edit(id)
        .then((response) => {
          if (response != null) {
            this.editmessage = response.data.data;
            this.message_type = this.$route.params.type;
            console.log("edit");
            this.editmessage.published_at = this.format_date(
              this.editmessage.published_at
            );

            this.editmessage.published_at =
              this.editmessage.published_at.replace(" ", "T");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    update(id) {
      let dataFromSourceEditing = $(".ck-source-editing-area").data("value");
      console.log(dataFromSourceEditing);

      if (dataFromSourceEditing) {
        this.editmessage.content = dataFromSourceEditing;
      }

      this.processing = true;
      setTimeout(this.enable, 3000);

      this.loader = true;
      if (this.editmessage.published_at) {
        this.editmessage.published_at = this.editmessage.published_at.replace(
          "T",
          " "
        );
        this.editmessage.published_at += ":00";
      }
      message
        .update(this.editmessage, id)
        .then(
          (response) => {
            this.$router.push({
              name: "Page Show",
              params: { type: this.message_type },
            });
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.loader = false;
          }
        )
        .catch(() => {})
        .finally(() => {});
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD hh:mm");
      }
    },

    enable: function () {
      this.processing = false;
    },
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
@import "~@/assets/front_component/js/timepicker/datepicker.css";
</style>
